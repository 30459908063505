import React from 'react';
import {Container, Typography, Box, Link, createTheme} from '@mui/material';
import {ThemeProvider as MuiThemeProvider} from '@mui/material/styles';
import {TypographyOptions} from "@mui/material/styles/createTypography";
import {BrowserRouter, BrowserRouter as Router, Route, Routes} from 'react-router-dom';
import {Link as RouterLink} from 'react-router-dom';

export const typography: TypographyOptions = {
    fontFamily: "'Abel', sans-serif;",
};

export const theme = createTheme({
    typography,
});

const OnlyFansPage: React.FC = () => {
    // You can customize this component as you like
    return (
        <Container>
            <Typography variant="body1"
                        sx={{
                            color: '#8c8a8a'
                        }}>
                Just checking to see if you're paying attention! While there's no 'exclusive content' here, feel free to explore the rest of the links for some real, non-fictional engagement.
            </Typography>
            <Link component={RouterLink} to="/" variant="h6"
                  sx={{textDecoration: 'none', color: '#8c8a8a'}}>
                home page
            </Link>
        </Container>
    );
};

const DashboardPage: React.FC = () => {
    // You can customize this component as you like
    return (
        <div style={{paddingTop: '25px'}}>
            <Container maxWidth="md">
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'start',
                        justifyContent: 'start',
                        height: '100vh',
                    }}
                >
                    <Typography variant="h5" color={'#595858'} fontWeight={500}>
                        Dmitry Lapitski
                    </Typography>
                    <Link href="https://www.linkedin.com/in/dmitry-lapitski/"
                          target="_blank"
                          rel="noopener"
                          variant="body1"
                          sx={{
                              textDecoration: 'none',
                              color: '#8c8a8a'
                          }}>
                        linkedin
                    </Link>
                    <Link href="https://www.instagram.com/dmitry_lapitski"
                          target="_blank"
                          rel="noopener"
                          variant="body1"
                          sx={{
                              textDecoration: 'none',
                              color: '#8c8a8a'
                          }}>
                        instagram
                    </Link>
                    <Link component={RouterLink} to="/onlyfans" variant="body1"
                          sx={{textDecoration: 'none', color: '#8c8a8a'}}>
                        onlyfans
                    </Link>
                    <Link href="mailto:dmitry@lapitski.com"
                          variant="body1"
                          sx={{
                              textDecoration: 'none',
                              color: '#8c8a8a'
                          }}>
                        email
                    </Link>
                </Box>
            </Container>
        </div>
    );
};

const App: React.FC = () => {
    return (
        <MuiThemeProvider theme={theme}>
            <BrowserRouter>
                <Routes>
                    <Route path="/" Component={DashboardPage}/>
                    <Route path="/onlyfans" Component={OnlyFansPage}/>
                </Routes>
            </BrowserRouter>
        </MuiThemeProvider>
    );
};

export default App;

